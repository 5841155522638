import React from 'react'

const GalleryItem = ({name, description, image, onClick}) => {
    return (
        <div className="gallery-item">
            <div className="gallery-item-image" onClick={onClick}>
                <img src={`/gallery/thumbnails/${image}`} alt={name}/>
            </div>
            {name && (
                <div className="gallery-item-name">{name}</div>
            )}
            {description && (
                <div className="gallery-item-description">{description}</div>
            )}
            
        </div>
    )
}

export default GalleryItem
