import React, {useState, useEffect} from 'react'
import { MobileView } from "react-device-detect";

import SEO from '../components/seo'
import Job from '../components/job'
import Portfolio from '../components/portfolio'

// Data
import data from '../data/resume.json';

const jobList = data.jobs;
const languageList = data.languages;
const skillsList = data.skills;
const toolsList = data.tools;

const ResumePage = () => {

    const languages = languageList.map(l => l.language);
    const years = languageList.map(l => l.years);
    const level = languageList.map(l => l.level);

    //console.debug(languages);

    const languageOptions = {
        chart: {
            toolbar: {
                show: false
            },
            height: 350,
            type: 'line',
            events: {
                click: function(chart, w, e) {
                    // console.log(chart, w, e)
                }
            }
        },
            
        stroke: {
            width: [0, 4]
        },
        plotOptions: {
                bar: {
                    horizontal: false,
                }
        },
        dataLabels: {
            enabled: false,
            enabledOnSeries: [1],
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff'
            },
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: false
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#ffffff'
                }
            },
            categories: languages,
            title: {
                text: 'Languages',
                style: {
                    color: '#fff'
                }
            }
        },
        yaxis: [{
            labels: {
                style: {
                    colors: '#ffffff'
                }
            },
            title: {
                text: 'Years',
                style: {
                    color: '#ffffff'
                }
            }
        },
            {
                min: 0,
                max: 10,
                tickAmount: 4,
                opposite: true,
                labels: {
                    style: {
                        colors: '#ffffff'
                    }
                },
                title: {
                    text: 'Skill Level',
                    style: {
                        color: '#ffffff'
                    }
                }
            }
    ]
    }

    const languageSeries = [{
        name: 'Years',
        type: 'bar',
        data: years,
        
    }, {
        name: 'Level',
        type: 'line',    
        data: level
    }]


    useEffect(() => {
        document.body.classList.add('resume-page');
        return () => {
            document.body.classList.remove('resume-page');
        }
    }, [])
    const [selectedTab, setSelectedTab] = useState('overview')

    const selectTab = (tabname) => {
        setSelectedTab(tabname)
    }

    const handleChange = (e) => {
        //console.log(e.target.value);
        setSelectedTab(e.target.value)
    }


    return (
        <>
            <SEO title="Resume" />
            <div className="tabbed-nav-wrapper">
                <MobileView>
                    <select className="mobile-resume-nav" onChange={handleChange}>
                        <option value="overview">Overview</option>
                        <option value="experience">Experience</option>
                        <option value="skills">Skills &amp; Education</option>
                        <option value="portfolio">Portfolio</option>
                    </select>
                </MobileView>
                <ul className="tabbed-nav">
                    <li className={`tab ${selectedTab === 'overview' ? 'tab-active': ''}`}><button onClick={() => selectTab('overview')}>Overview</button></li>
                    <li className={`tab ${selectedTab === 'experience' ? 'tab-active' : ''}`}><button onClick={() => selectTab('experience')}>Experience</button></li>
                    <li className={`tab ${selectedTab === 'skills' ? 'tab-active' : ''}`}><button onClick={() => selectTab('skills')}>Skills &amp; Education</button></li>
                    <li className={`tab ${selectedTab === 'portfolio' ? 'tab-active' : ''}`}><button onClick={() => selectTab('portfolio')}>Portfolio</button></li>
                </ul>
                
            </div>
            <div className="resume-page-content">
                {selectedTab === 'overview' &&(
                    <div className="overview-content">
                        <h2 className="resume-color ">Overview</h2>
                        <div className="overview-text">
                            <p><span className="lede">My name is Kevin Williams.</span> I am a UI/UX designer and front-end developer currently residing in the Birmingham area of Alabama in the Southeastern United States. I have been working as a designer for nearly 20 years;
                                 working in graphic design and web design and eventually to UI and UX design. During that time frame I kind of fell into the development side of things as well. I started
                            off by implementing my designs into other developers code and then became more and more involved with the whole development process, ultimately picking up work as a front-end developer. </p>

                            <p>My first love is design. I enjoy the process of trying to figure out the best way to show information to an audience that is intuitive, understandable, concise, but still aesthetically pleasing.
                                That is not always an easy task, however it is rewarding when it can be done well.
                            </p>

                            <p>The development side of things is an interest that turned into something more. At first it was out of necessity; I needed someone to be able to implement the work I was doing and I was the only one around. From there it turned into something more. 
                                I got deeper into the development side of the equation as a way to better understand the tools that were being used to build sites and applications. I felt that I could be a better designer by knowing the tool sets that the developers would be using. If I can know the tools I can create designs that 
                                work with the development stack; not fight it.
                            </p>

                            <p>So I continue to learn and explore. In the past few years I have become much more aware of the impact of design and how the choices made can have bias towards one group or another. I have begun to understand and really appreciate the need for inclusive design practices. 
                                I am learning more and more how to design and build for the widest number of users. While this area is new to me and I am still learning, it is an aspect that I want to continue to learn and understand, because one day any one of us may need a little extra help
                                navigating and interacting with our world.
                            </p>
                        </div>
                    </div>
                )}
                {selectedTab === 'experience' && (
                    <div className="experience-content">
                    <h2 className="resume-color">Experience</h2>
                    <div className="app-inner-content">
                        {
                            jobList.map(job => (
                                <Job
                                    key={job.id} 
                                    Title={job.title} 
                                    Company={job.company} 
                                    Dates={job.dates} 
                                    Description={job.description}
                                    Current={job.current} >
                                    {
                                        job.projects.map((projects, i) => {
                                            return (
                                                <li key={i}>
                                                    {projects}
                                                </li>
                                            );
                                        })
                                    }
                                </Job>
                            )
                            )}
                    </div>
                    </div>
                )}
                {selectedTab === 'skills' && (
                    <div className="skills-content">
                        <h2 className="resume-color">Skills &amp; Education</h2>
                        <h3>Tools</h3>
                        
                        <ul className="tool-list">
                            {toolsList.map((t,i) => (
                                <li key={i}>{t}</li>
                            ))}
                        </ul>

                        <h3>Languages</h3>
                        <ul className="competency-list">
                            {languageList.map((l, i) => (
                                <li key={i} 
                                    className={`ring ${l.level >= 6 ? 'ring-green' : l.level >= 4 ? 'ring-blue' : 'ring-yellow'}`}>
                                        <div>
                                            <span className="skill">{l.language}</span> 
                                            <span className="years">{l.years} {l.years === 1 ? 'year' : 'years'}</span>
                                        </div>
                                    </li>    
                            ))}
                        </ul>

                        <h3>Skills</h3>
                        <ul className="competency-list">
                            {skillsList.map((s,i) => (
                                <li key={i} className={`ring ${s.level >= 7 ? 'ring-green' : 'ring-blue'}`}>
                                    <div>{s.skill}</div>
                                </li>
                            ))}
                        </ul>


                        <h3>Education</h3>
                        <p>B.A., University of Alabama at Birmingham, Studio Art/Design</p>

                    </div>
                    
                )}
                {selectedTab === 'portfolio' && (
                    <div className="portfolio-content">
                    <h2 className="resume-color">Portfolio</h2>
                    <Portfolio />
                </div>
                )}    
                </div>
        </>
    )
}

export default ResumePage
