import React, { useState, useEffect, useRef }from 'react'
import GalleryItem from '../components/galleryItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import data from '../data/portfolio.json'

const portfolio = data.portfolio


const Portfolio = () => {
    const node = useRef()
    const [modalOpen, setModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalImage, setModalImage] = useState('')
    const [modalType, setModalType] = useState('')

    useEffect(() => {

        const handleKeyPress = (e) => {
            if (e.key === 'Escape' || e.key === 'Esc') {
                closeModal(e)
            }
        }

        const handleClickOutside = e => {
            if (node.current.contains(e.target)) {
                return;
            }
            closeModal(e)
        }

        if (modalOpen === true) {
            document.addEventListener("keyup", handleKeyPress)
            document.addEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("keyup", handleKeyPress)
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [modalOpen])



    const handleModal = (name, image, type) => {
        document.body.classList.add('modal-open')
        setModalTitle(name)
        setModalImage(image)
        setModalType(type)
        setModal(true)
    }
    const closeModal = (e) => {
        e.preventDefault()
        document.body.classList.remove('modal-open')
        setModalTitle('')
        setModalImage('')
        setModal(false)
    }
    return (
        <>
            {portfolio.map((folio, i) => (
                <div key={i} className={`gallery gallery-${folio.type}`}>
                    <h3>{folio.section}</h3>
                    <div className="gallery-project-wrapper">
                        {folio.projects.map((project, i) => (
                            <div key={i} className="gallery-project">
                            <h4>{project.name}</h4>
                            <p className="description">{project.description}</p>
                            <div className="gallery-items">
                            {project.gallery.map((item,i) => (
                               <GalleryItem key={i} name={item.name} description={item.description} image={item.image} onClick={(e) => handleModal(item.title ? item.title : item.name, item.image, folio.type)} /> 
                            ))}
                            </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {modalOpen && (
                <div className={`gallery-item-modal-wrapper gallery-modal-${modalType}`}>
                    <div ref={node} className="gallery-item-modal-content">
                        <div className="gallery-item-modal-header">
                            <div className="gallery-item-modal-header-title">{modalTitle}</div>
                            <button className="close" onClick={(e) => closeModal(e)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <div className="gallery-item-modal-image">
                            <img src={`/gallery/${modalImage}`} alt={modalTitle} />
                        </div>
                    </div>
                </div>
            )}  
        </>
    )
}

export default Portfolio
